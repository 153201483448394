<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-4">
        <h1>Purchase orders</h1>
      </v-col>
      <v-col class="col-8 text-right">
        <h1>Total: {{ total | currency }}</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-form @submit.prevent="getPurchaseOrders">
          <v-row class="pt-8">
            <v-col>
              <label>Supplier</label>
              <v-text-field v-model="search.supplier"></v-text-field>
            </v-col>
            <v-col>
              <label>Order no</label>
              <v-text-field v-model="search.orderNo"></v-text-field>
            </v-col>
            <v-col>
              <label>Description</label>
              <v-text-field v-model="search.description"></v-text-field>
            </v-col>
            <v-col>
              <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="search.date"
                persistent
                width="350px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <label class="pl-8">Date</label>
                  <v-text-field
                    v-model="search.date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="search.date"
                  range>
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="modal = false"
                  >
                    Cancel
                  </v-btn>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog.save(search.date)"
                  >
                    OK
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col>
              <label>Open?</label>
              <v-select class="pt-3" :items="statuses" v-model="search.open">
              </v-select>
            </v-col>
            <v-col>
              <label>Complete?</label>
              <v-select class="pt-3" :items="statuses" v-model="search.complete">
              </v-select>
            </v-col>
            <v-col>
              <label>Cancelled?</label>
              <v-select class="pt-3" :items="statuses" v-model="search.cancelled">
              </v-select>
            </v-col>
            <v-col>
              <v-btn class="mb-2" x-small block type="sumbit">Search</v-btn>
              <v-btn class="mb-2" x-small block @click="clearSearch">Clear</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-12">
        <v-simple-table fixed-header height="400">
          <thead>
            <tr>
              <th>PO No</th>
              <th>Supplier</th>
              <th>Date raised</th>
              <th>Date authed</th>
              <th>Date ordered</th>
              <th>Ordered by</th>
              <th>Delivery date</th>
              <th>Value</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(po, index) in purchaseOrders" :key="`s_${index}`">
              <td>#{{ po.id }}</td>
              <td>{{ po.supplier.name }}</td>
              <td>{{ po.date_of_requisition | tinyDate }}</td>
              <td>{{ po.date_of_authorisation | tinyDate }}</td>
              <td>{{ po.date_of_order | tinyDate }}</td>
              <td><span v-if="po.orderer">{{ po.orderer.name }}</span></td>
              <td>{{ po.delivery_date | tinyDate }}</td>
              <td>{{ po.order_value | currency }}</td>
              <td>
                <router-link custom v-slot="{ navigate }" :to="`/purchasing/purchase-orders-view/${po.id}`">
                  <v-btn
                    x-small
                    @click="navigate"
                    @keypress.enter="navigate"
                    role="link">
                    View
                  </v-btn>
                </router-link>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
        <v-pagination
          class="pt-4"
          v-model="page"
          :length="length"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
          :total-visible="totalVisible"
        ></v-pagination>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'PurchasingIndex',
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      purchaseOrders: [],
      page: 1,
      length: 0,
      modal: false,
      totalVisible: 9,
      search: {
        cancelled: 2,
        complete: 2,
        date: [],
        description: '',
        open: 1,
        orderNo: '',
        supplier: '',
      },
      statuses: [
        { text: 'All', value: 0, },
        { text: 'Yes', value: 1, },
        { text: 'No', value: 2, },
      ],
      total: 0,
    };
  },
  watch: {
    page() {
      this.getPurchaseOrders();
    },
  },
  methods: {
    clearSearch() {
      this.search = {
        cancelled: 0,
        complete: 0,
        date: [],
        description: '',
        open: 0,
        orderNo: '',
        supplier: '',
      };
      this.getPurchaseOrders();
    },
    setPurchaseSearchParams() {
      const params = {};
      params.cancelled = this.search.cancelled;
      params.complete = this.search.complete;
      params.date = this.search.date;
      params.description = this.search.description;
      params.open = this.search.open;
      params.orderNo = this.search.orderNo;
      params.supplier = this.search.supplier;
      this.$store.commit('setPurchasingSearchParams', params);
    },
    getSearchParams() {
      this.search.cancelled = this.$store.state.purchasingSearchParams.cancelled;
      this.search.complete = this.$store.state.purchasingSearchParams.complete;
      this.search.date = this.$store.state.purchasingSearchParams.date;
      this.search.description = this.$store.state.purchasingSearchParams.description;
      this.search.open = this.$store.state.purchasingSearchParams.open;
      this.search.orderNo = this.$store.state.purchasingSearchParams.orderNo;
      this.search.supplier = this.$store.state.purchasingSearchParams.supplier;
      this.getPurchaseOrders();
    },
    getPurchaseOrders() {
      this.setPurchaseSearchParams();
      const postData = {};
      postData.search = this.search;
      axios.post(`/purchaseOrders/getAll/${this.page}.json?token=${this.token}`, postData)
        .then((response) => {
          this.purchaseOrders = response.data.purchaseOrders;
          this.length = response.data.length;
          this.total = response.data.total;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    this.getSearchParams();
  },
};
</script>
